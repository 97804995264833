import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { useDispatch, useSelector } from 'react-redux';
import KADICK_LOGO from "../../kadick_b2c_logo.png"
import DateBetweenComponent from '../../components/DateBetweenComponent';
import { promoRunStatusFetch, promoRunStatusLastAdded } from '../../redux/slice/PromoRunStatusSlice';
import ButtonCompo from '../../components/ButtonCompo';
import { Grid } from '@mui/material';
import jsPDF from "jspdf";
import { currentDateWithTime } from '../../utlis/CurrentDateGenerator';


const useStyles = makeStyles({
  root: {
    "& .css-sq9qdz": {
      justifyContent: "end",
      flexDirection: "row-reverse"
    }
  }
});
const PromoRunStatus = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.PromoRunStatusReducer);

  //console.log("selectValuesRe",selectValuesRe)
  const lastAdded = selectValuesRe.lastAdded;
  //console.log("lastAdded",lastAdded)
  const snackbarValues = selectValuesRe.sucessMessage;
  //console.log("snackbarValues",snackbarValues)
  //   const promoDistributionAddAndEditModal = selectValuesRe.promoDistributionAddAndEditModal;
  // const [createModalOpen, setCreateModalOpen] = useState(false);
  ///const [tableData, setTableData] = useState(selectValuesRe.promoDistributionReducer.promoDistributions);
  //   const [validationErrors, setValidationErrors] = useState({});
  // const [tableData, setTableData] = useState(selectValuesRe.promoDistributionReducer.promoDistributions);
  const tableData = selectValuesRe.lists;


  const handlePrint = (value) => {
    // console.log(e)
    const tableColumns = ["#", "Create Time", "Selected Count", "Winner Count"];
    const doc = new jsPDF();
    const firstArray = [];
    var secondArray = [];
    tableData.forEach(element => {
      secondArray = [];
      //  //console.log("element[x].toString()1",element)
      for (const x in element) {
        //console.log("element[x].toString()1",element)
        secondArray.push(element?.id)
        secondArray.push(element?.date)
        secondArray.push(element?.scount)
        // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
        secondArray.push(element?.wcount)


      }
      firstArray.push(secondArray)
    });
    doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });

    doc.text("Promo Runner Status", 70, 25);
    doc.text(currentDateWithTime, 140, 25);
    doc.addImage(KADICK_LOGO, 15, 10, 30, 20);
    if (value === 2) {
      doc.save("PromoRunnerStatus.pdf");
    }
    if (value === 1) {
      doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
    }
    window.open(doc.output('bloburl'), '_blank');
  }




  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: '#',

      },
      {
        accessorKey: 'date',
        header: 'Create Time',
      },

      {
        accessorKey: 'scount',
        header: 'Select Count',

      },
      {
        accessorKey: 'wcount',
        header: 'Winner Count',

      },
    ],
    [],
  );
  const handleQuery = () => {
    dispatch(promoRunStatusFetch({ startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
  }
  const handleInputDateChange = (e) => {
    const { name, value } = e.target;
    dispatch(promoRunStatusLastAdded({ [name]: value }))
  }
  //   const snackbarClosedReset= () => {
  //     dispatch(snackbarClosed({ snackbarOpen: false }))
  //   }
  //   const handleDelete = (row) => {
  //    // const findElement = tableData.find(element=>element.id === row.original.id)
  //     //console.log("tableData",findElement)
  //     dispatch(promoDistributionDelete({promoDistributionId:row.original.id}))

  //   }
  //   const openMod = () => {
  //    // alert("s")
  //     dispatch(popupOpenAndClosed ({open:true,mode:"add",active:"Y"}));
  //     var resetObj = {};
  //     for(const x in lastAdded) {
  //       //console.log("x",x)
  //       if(x !== "active") {
  //        Object.assign(resetObj,{[x]:""})
  //       }
  //     }
  //     //console.log("resetObj",resetObj)
  //     dispatch(promoDistributionLastAdded(resetObj, {active:"Y"}))
  //   }
  //   const handleEditPopUp = (row) =>{
  //     //console.log("row",row)
  //     const findElement = tableData.find(element=>element.id === row.original.id)
  //    //console.log("tableData",findElement)
  //     dispatch(popupOpenAndClosed ({open:true,mode:"edit"}))
  //     dispatch(promoDistributionLastAdded({promoDistributionName:findElement.name,promoDistributionId:findElement.id,active:findElement.active}))
  //   }
  return (
    < div className={classes.root}>
      <Grid container>
        <DateBetweenComponent handleInputDateChange={handleInputDateChange} lastAdded={lastAdded} />
        <ButtonCompo handlePrint={handlePrint} handleInputDateChange={handleInputDateChange} action={true} pdf={true} print={true} handleQuery={handleQuery} query={true} />
      </Grid>
      <MaterialReactTable
        muiTablePaginationProps={{
          //rowsPerPage : 5
        }}
        displayColumnDefOptions={{
          'mrt-row-actions': {
            muiTableHeadCellProps: {
              align: 'center',
            },
            size: 120,
          },
        }}
        enableStickyHeader={true}
        columns={columns}
        data={tableData}

        muiTableBodyRowProps={{
          sx: {
            padding: "0px"
          }
        }}
        initialState={{
          density: "compact"
        }}
        muiTableContainerProps={{
          sx: {
            maxHeight: "400px",
            minHeight: "400PX"
          }
        }}
        //  positionActionsColumn = "last"
        //  editingMode="modal" //default
        enableColumnOrdering
      //   enableEditing
      // onEditingRowSave={handleSaveRowEdits}
      // onEditingRowCancel={handleCancelRowEdits}
      // renderRowActions={({ row, table }) => (
      //   <Box sx={{ display: 'flex', gap: '1rem' }}>
      //     <Tooltip arrow placement="right" title="Edit">
      //       <IconButton onClick={()=>handleEditPopUp(row)}>
      //         <Edit />
      //       </IconButton>
      //     </Tooltip>
      //     <Tooltip arrow placement="right" title="Delete">
      //       <IconButton  onClick={()=>handleDelete(row)} color="error" >
      //         <Delete />
      //       </IconButton>
      //     </Tooltip>
      //   </Box>
      // )}
      // renderTopToolbarCustomActions={() => (
      //   <>
      //   <Button
      //     color="primary"
      //     onClick={() => {openMod() }}
      //     variant="contained"
      //   >
      //     Create Promo Distribution
      //   </Button></>
      // )}
      />

      {/* 
<SnackBar snackbarClosed={snackbarClosedReset} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} /> */}


      <div>

      </div>


    </div>
  );
};



export default PromoRunStatus;
