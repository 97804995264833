import { Autocomplete, Grid, Paper, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Input from "../../components/input";

import { THIS_FIELD_IS_REQUIRED } from "../../constfiles/formValidation";
import ButtonComponent from "../../components/button";
import "../../App.css"
import CircularStatic from "../../components/spinner";
import SerialEnquiryResultTable from "../CommonComponent/SerialEnquiryResultTable";
import SnackBarComponent from "../../components/snackbar";

import SerialEnquiryResultTable2 from "../CommonComponent/SerialEnquiryResultTable2";
import { operationSerialEnqLastAdded, getListOperationSerialNoEnq, getSource,lastAddedReset, serialEnqLastAdded, snackbarClosed, } from "../../redux/slice/OperationSerialNoEnqSlice";
export default function SerialEnquiry() {
    const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(getSource())
    },[])
   
    const selectValuesRe = useSelector(state => state.OperationSerialNoEnqReducer);
    const sources = selectValuesRe?.sources;
    const snackbarValues = selectValuesRe.sucessMessage;
    const lastAdded = selectValuesRe.lastAdded
    const list =  selectValuesRe?.lists ? selectValuesRe?.lists[0] : []
   // console.log("lastAdded", lastAdded);
    const loader = selectValuesRe?.loader;
    const autoCompleteChange = (e, typeNewValue, name) => {
      //   console.log("typeNewValue", typeNewValue)
        handleInputChangeValidation(name, typeNewValue)
       
        dispatch(operationSerialEnqLastAdded({ [name]: typeNewValue?.prefix, [name + "Display"]: typeNewValue?.label, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
       // console.log(name,value)
        dispatch(operationSerialEnqLastAdded({ [name]: value, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
    }
    function handleInputChangeValidation(name, value) {
        if (value.toString().trim() === "") {
            dispatch(operationSerialEnqLastAdded({ [name + "Error"]: true, [name + "HelperText"]: THIS_FIELD_IS_REQUIRED }))
        }
        else {
            dispatch(operationSerialEnqLastAdded({ [name + "Error"]: false, [name + "HelperText"]: "" }))
        }
    }

    const handleClick = () => {
        dispatch(getListOperationSerialNoEnq({ serialNo: lastAdded.serialNo, source: lastAdded.source }))
    }
    const handleReset = () => {
        dispatch(lastAddedReset({ serialNo: lastAdded.serialNo, source: lastAdded.source }))
    }
    const snackbarClosedReset = () => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    return (
        <>
            {loader === true ? <CircularStatic /> : null}
            <h3 style={{textAlign:"center"}}>USSD Serial Number Inquiry</h3>
            <Grid container>
                <Grid item lg={4} md={4} sm={4}>
                    <Autocomplete
                        id="Source"
                        options={sources}
                        value={lastAdded.sourceDisplay}
                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "source") }}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='source' error={lastAdded.sourceError} helperText={lastAdded.sourceHelperText} {...params} label="Source" /></Grid>}
                    />
                </Grid>
                <Grid item lg={4} md={4} sm={4}>
                    <Input
                        value={lastAdded.serialNo}
                        //  style={{ width: "72%" }}
                        fullWidth={true}
                        placeholder="Serial No"
                        type="text"
                        touched={lastAdded.serialNoTouched}
                        onChange={handleInputChange}
                        name="serialNo"
                        parentStyle={{ width: "100%" }}
                        error={lastAdded.serialNoError}
                        helperText={lastAdded.serialNoHelperText}
                        label="SerialNo" />
                </Grid>
                <Grid item lg={4} md={4} sm={4} sx={{
                    paddingLeft: "1%"
                }}>

                    <ButtonComponent
                        variant='contained' color='primary' label="Check"
                        onClick={handleClick}
                    >
                    </ButtonComponent>&nbsp;
                    <ButtonComponent
                        variant='contained' color='primary' label="Reset"
                        onClick={handleReset}
                    >
                    </ButtonComponent>
                </Grid>
            </Grid>

           <SerialEnquiryResultTable2 list={list} source={lastAdded.sourceDisplay}/>
           <SnackBarComponent dispatch={dispatch}  snackbarClosed={snackbarClosedReset} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </>
    )
}