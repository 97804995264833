import { Autocomplete, Grid, Paper, TextField } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import ButtonComponent from "../../components/button";
import "../../App.css"
import { Delete, Edit } from '@mui/icons-material';
import CircularStatic from "../../components/spinner";
import SnackBarComponent from "../../components/snackbar";
import { Box, Button, IconButton, Tooltip, } from '@mui/material';
import { denominationFetch, handleRest, operatorFetch, snackbarClosed, stockSummaryFetch, stockSummaryLastAdded, stockSummaryLastAddedValidate } from "../../redux/slice/StockSummarySlice.";
const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            justifyContent: "end",
            flexDirection: "row"
        }
    }
});
export default function StockSummary() {
    const classes = useStyles()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(operatorFetch())
        dispatch(denominationFetch())
        return () =>{
            dispatch(handleRest())        
        }
    }, [])

    const selectValuesRe = useSelector(state => state.StockSummaryReducer);
    const operators = selectValuesRe?.operators === undefined ? [] : selectValuesRe.operators
    const denominations = selectValuesRe?.denominations === undefined ? [] : selectValuesRe.denominations
    const snackbarValues = selectValuesRe.sucessMessage;
    const lastAdded = selectValuesRe.lastAdded
    const tableData = selectValuesRe.lists === undefined ? [] : selectValuesRe.lists
   // console.log("operators", operators);
    const loader = selectValuesRe?.loader;
    const autoCompleteChange = (e, typeNewValue, name) => {
      //  console.log("typeNewValue", typeNewValue)
        handleInputChangeValidation(name, typeNewValue)
        if(name === "operator") {
            dispatch(stockSummaryLastAdded({ [name]: typeNewValue?.id, [name + "Display"]: typeNewValue?.label, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
        }
        else {
        dispatch(stockSummaryLastAdded({ [name]: typeNewValue?.code, [name + "Display"]: typeNewValue?.label, [name + "typeTouched"]: true, [name + "HelperText"]: true }))
        }
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'operators.code',
                header: 'Operator',
            },
            {
                accessorKey: 'denomination',
                header: 'Den.',

            },
            {
                accessorKey: 'ssno',
                header: 'SSNO',

            },
            {
                accessorKey: 'esno',
                header: 'ESNO',

            },

            {
                accessorKey: 'aCount',
                header: 'Available Count',

            },
            {
                accessorKey: 'cTime',
                header: 'Create Time',

            },
        ],
        [],
    );
    function handleInputChangeValidation(name, value) {
        if (value.toString().trim() === "") {
            //    dispatch(operationSerialEnqLastAdded({ [name + "Error"]: true, [name + "HelperText"]: THIS_FIELD_IS_REQUIRED }))
        }
        else {
            // dispatch(operationSerialEnqLastAdded({ [name + "Error"]: false, [name + "HelperText"]: "" }))
        }
    }

    const handleClick = () => {
        dispatch(stockSummaryFetch({ operator: lastAdded.operator, denomination: lastAdded.denomination }))
    }
    const handleReset = () => {
            dispatch(handleRest())
    }
    const snackbarClosedReset = () => {
        dispatch(snackbarClosed({ snackbarOpen: false }))
    }
    return (
        <>
            {loader === true ? <CircularStatic /> : null}
            {/* <h3 style={{ textAlign: "center" }}>Stock Summary</h3> */}

            < div className={classes.root}>
                <MaterialReactTable
                    muiTablePaginationProps={{
                        //rowsPerPage : 5
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            muiTableHeadCellProps: {
                                align: 'center',
                            },
                            size: 120,
                        },
                    }}
                    state={{
                        isLoading: loader,
                        showProgressBars: loader,
                        showSkeletons: loader
                    }}
                    initialState={{
                        density: "compact",
                        isLoading: loader,
                        showProgressBars: loader,
                        showSkeletons: loader
                    }}
                    enableStickyHeader={true}
                    columns={columns}
                    data={tableData}

                    muiTableBodyRowProps={{
                        sx: {
                            padding: "0px"
                        }
                    }}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: "400px",
                            minHeight: "400PX"
                        }
                    }}
                    //positionActionsColumn="last"
                    //  editingMode="modal" //default
                    enableColumnOrdering
                  
                    renderTopToolbarCustomActions={() => (
                        <>

                            <Grid container>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Autocomplete
                                        id="Operator"
                                        options={operators}
                                        value={lastAdded.operatorDisplay}
                                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "operator") }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='operator' error={lastAdded.operatorError} helperText={lastAdded.operatorHelperText} {...params} label="operator" /></Grid>}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4}>
                                    <Autocomplete
                                        id="Denomination"
                                        options={denominations}
                                        value={lastAdded.denominationDisplay}
                                        onChange={(e, newValue, name) => { autoCompleteChange(e, newValue, "denomination") }}
                                        sx={{ width: "100%" }}
                                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField name='denomination' error={lastAdded.denominationError} helperText={lastAdded.denominationHelperText} {...params} label="denomination" /></Grid>}
                                    />
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} sx={{
                                    paddingLeft: "1%"
                                }}>

                                    <ButtonComponent
                                        variant='contained' color='primary' label="Check"
                                        onClick={handleClick}
                                    >
                                    </ButtonComponent>&nbsp;
                                    <ButtonComponent
                                        variant='contained' color='primary' label="Reset"
                                        onClick={handleReset}
                                    >
                                    </ButtonComponent>
                                </Grid>
                            </Grid>
                        </>
                    )}
                />
            </div>

            <SnackBarComponent dispatch={dispatch} snackbarClosed={snackbarClosedReset} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </>
    )
}