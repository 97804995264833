import React, {  useEffect, useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import CircularStatic from '../../components/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { cardVoucherAllStocksFetch, cardVoucherReservedStocksFetch, cardVoucherStagedStocksFetch } from '../../redux/slice/CardVoucherAllStockSlice';
ChartJS.register(ArcElement, Tooltip, Legend);


function CardVoucherStagedStockChart() {

  const dispatch = useDispatch()
  const selectValuesRe = useSelector(state => state.CardVoucherAllStocksReducer);
  const tableData = selectValuesRe?.cardVoucherStagedStocks?selectValuesRe?.cardVoucherStagedStocks:[];
  const [operatorData, setOperatorData] = useState([])
  const [count, setCount] = useState([])
  const loader = selectValuesRe?.loader
  useEffect(() => {
    dispatch(cardVoucherStagedStocksFetch())
  }, [])

  useEffect(() => {
    if (tableData.length > 0) {
      setOperatorData(tableData.map(item => {
        return item.coperators.operatorCode + " - " + item.vocuherValue
      }))
      setCount(tableData.map(item => {
        return item.count
      }))
    }
    else {
      setOperatorData(["No Data Found"])
      setCount(0)
    }
  }, [tableData.length])
  const data = {
    labels: operatorData,
    datasets: [
      {
        label: '# of Stocks',
        data: count,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          '#32831B',
          '#00FFFF',
          '#FF5733',
          '#E7A395',
          '#E7D495',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  return( <div style={{height:"450px", width:"100%",textAlign:"-webkit-center",margin:"auto"}}> 
  <h3>Card Voucher Staged Stock Chart</h3>
  {loader===true ? <CircularStatic /> : null }
  <Pie data={data}  /></div>);
}
export default CardVoucherStagedStockChart;