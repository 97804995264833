import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import axios from 'axios';
import { makeStyles } from '@material-ui/styles';
import CustomizedMenus from './components/Menu';
// import MenuConfig from './utlis/MenuConfigurations';
import { useSelector } from 'react-redux';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import { Logout } from '@mui/icons-material';
import { Navigate, useNavigate } from 'react-router';
import SignIn from './views/signin/signin';
axios.defaults.baseURL = "http://localhost:3001/";
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));
const useStyles = makeStyles({
  root: {
    "& .css-usg3v-MuiDrawer-docked .MuiDrawer-paper": {
      //     background: "linear-gradient(45deg, #FE7659 30%, #ff8e53 90%)",

    }
  }
});
axios.interceptors.response.use(response => {
  //console.log("Response", response);


  return response
}, err => {
  const response = err?.response;;
  if (response.status === 401) {
    axios.defaults.headers.common["AuthCode"] = ""
    window.location.href = window.location.origin+"/ussdweb/"
  }
})


// const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
//   ({ theme, open }) => ({
//     flexGrow: 1,
//     padding: theme.spacing(3),
//     transition: theme.transitions.create('margin', {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen,
//     }),
//     marginLeft: `-${drawerWidth-100}px`,
//     ...(open && {
//       transition: theme.transitions.create('margin', {
//         easing: theme.transitions.easing.easeOut,
//         duration: theme.transitions.duration.enteringScreen,
//       }),
//       marginLeft: 0,
//     }),
//   }),
// );
export const MainContext = React.createContext()
export default function ComponentBuild({ Component: component, title }) {

  const theme = useTheme();
  const classes = useStyles()
  const selectValuesRe = useSelector(state => state.CommonReducer);
  //console.log("selectValuesRe common", selectValuesRe)
  axios.defaults.headers.common["AuthCode"] = selectValuesRe.users.auth;


  const authCode = selectValuesRe.users.auth;
  const userDetails = selectValuesRe.userDetails;
  axios.defaults.headers.common["UserId"] =userDetails.userId;
  //console.log("userId componentbuild",userDetails.userId);
  //console.log("userDetails", userDetails)
  const history = useNavigate();
  const [open, setOpen] = React.useState(false);
  const MenuConfig = selectValuesRe.menus;
  //console.log("MenuConfig", MenuConfig)
  axios.interceptors.request.use(request => {
   // console.log(request)
    return request
  })
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClickLogout = () =>{
    localStorage.clear();
    history("/")
  }


  return (
    <>
    {authCode ?
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ background: "linear-gradient(45deg, #FE7659 30%, #ff8e53 90%)", }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: 'none' }) }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography variant="h6" noWrap
            onClick={() => (history("/"))}
            component="div" style={{
              display: "inline-flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center"
            }}
          > */}
          <div style={{
            display: "inline-flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center"
          }}><h5>
              {/* USSD  &#8594;  */}
              <span> {title}</span></h5>
            <div>Welcome !! &nbsp;<span style={{ color: "blue" }}> {userDetails.profileIDesc} ({userDetails?.userName}) &nbsp;</span> {parseInt(userDetails?.invalidAttempt) > 0 && "  | Invalid Attempt " + userDetails?.invalidAttempt} | &nbsp; Last Login: {userDetails.lastLoginDate}
              {/* ROOT USER (root) | Invalid Attempt: 1 | Last Login: 2023-07-09 13:17:40  */}
              &nbsp; <IconButton onClick={handleClickLogout}>
                <Logout />
              </IconButton>
            </div>
          </div>

          {/* </Typography> */}
        </Toolbar>
      </AppBar>
      <MainContext.Provider value={{ main: open }}>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
              background: "linear-gradient(45deg, #FE7659 30%, #ff8e53 90%)",
            },

          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </DrawerHeader>
          {/*  */}<Divider />

          {MenuConfig && MenuConfig.map((item, index) => {
            return (
              <div key={index}>
                <CustomizedMenus path={item.path} childs={item.childs} name={item.name} />
              </div>
            )
          })
          }

        </Drawer>
        <Main open={open} sx={{ overflowX: "hidden" }}>
          <DrawerHeader />


          {component}


        </Main >
      </MainContext.Provider>
    </Box >
        : <SignIn/> }
        </>
  );
}