import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const intialLists = [{
    VoucherInfoBelongProviders: {
        sp_name: "-"
    },
    operators: {
        opname: "-"
    },
    denomination: "-",
    voucherType: "-",
    id: "-",
    batchNumber: "-",
    chainId: "-",
    dealerCode: "-",
    expiryDate: "-",
    voucherRequestId: "-",
    status: "",
    OperationSerialNo: "-",
    tid: "-",
    ttime: "-",

}]
const initialState = {
    loader: false,
    sources: [{
        name: "Main", label: "Main", value: "Main"
    }],
    sucessMessage: {
        snackbarOpen: false,
        message: "",
        mode: "success"
    }
    , lastAdded: {
        "serialNo": "",
        "operator": "",
        "sourceDisplay": ""

    },
    lists: intialLists
}



export const getSource = createAsyncThunk(
    "getSource/add", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/oprSerialNoEnq/getSource', arg);
            const sourcesData = response.data;
            sourcesData.splice(0, 0, {
                name: "Main", label: "Main", value: "Main",prefix:"Main"
            })
            console.log("sourcesData", sourcesData)
            return sourcesData
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)

export const getListOperationSerialNoEnq = createAsyncThunk(
    "getListOperationSerialNoEnq/getLis", async (arg, thunkAPI) => {
        try {
            const response = await axios.post('/oprSerialNoEnq/getList', arg);
            //console.log("responseadd", response.data)

            return response.data
        }
        catch (error) {
            //console.log("error", error.response)
            return error.response.status
        }
    }
)


const OperationSerialNoEnqSlice = createSlice({
    name: "OperationSerialNoEnqReducer",
    initialState,
    reducers: {
        operationSerialEnqLastAdded(state, action) {
            console.log(state,action)
            return {
                ...state,
                lastAdded: { ...state.lastAdded, ...action.payload },
            }
        },
        lastAddedReset(state, action) {
            //    //console.log("errpr ope", action.payload)
            return {
                ...state,
                lastAdded: {
                    "OperationSerialNo": "",
                    "operator": ""

                },
                lists: intialLists
            }
        },
        snackbarClosed(state, action) {
            //  //console.log("action", action)
            return {
                ...state,
                sucessMessage: { ...state.sucessMessage, ...action.payload }
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getListOperationSerialNoEnq.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(getListOperationSerialNoEnq.rejected, (state, action) => {

                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        },
                        loader:false
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        },
                        loader:false
                    }
                }
            })
            .addCase(getListOperationSerialNoEnq.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        sucessMessage: {
                            message: "New Request Added Failed",
                            snackbarOpen: true,
                            mode: "warning"
                        },
                        loader: false
                    }
                }
                else {
                    return {
                        ...state,
                        sucessMessage: {
                            message: action.payload.message,
                            snackbarOpen: true
                        },
                          lists:action.payload.response ? action.payload.response : [],
                          loader: false,
                       // sources: action.payload

                    }
                }
            }).addCase(getSource.pending, (state, action) => {
                return {
                    ...state,
                    loader: true
                }
            })
            .addCase(getSource.rejected, (state, action) => {

                if (action.payload.toString() === "500") {
                    return {
                        ...state,
                        loader: false,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
                else {
                    return {
                        ...state,
                        loader: false,
                        sucessMessage: {
                            message: action.payload,
                            snackbarOpen: true,
                            mode: "warning"
                        }
                    }
                }
            })
            .addCase(getSource.fulfilled, (state, action) => {
                //console.log("action fulfilled fulfilled==>", action)
                if (action.payload.toString() === "500") {
                    return {
                        ...state,

                        loader: false
                    }
                }
                else {
                    return {
                        ...state,

                        sources: action.payload,
                        loader: false

                    }
                }
            })



    }

})

export const { operationSerialEnqLastAdded, snackbarClosed, lastAddedReset } = OperationSerialNoEnqSlice.actions;
export const OperationSerialNoEnqReducer = OperationSerialNoEnqSlice.reducer