import React, { useEffect, useMemo } from "react"
import { useSelector,useDispatch } from "react-redux"
import MaterialReactTable from 'material-react-table';
import jsPDF from "jspdf";
import RetailerCommonWithDateComponent from "./RetailerWithDateComponent"
import { RetailerPaymentFetch, RetailerPaymentLastAddedReset, handleSnackBar } from "../../redux/slice/RetailerPaymentStatementSlice"
import { RetailerPaymentLastAdded } from "../../redux/slice/RetailerPaymentStatementSlice"
import { currentDateWithTime, daysDiffernece } from "../../utlis/CurrentDateGenerator";
import SnackBarComponent from "../../components/snackbar";
import KADICK_LOGO from "../../kadick_b2c_logo.png";
export default function RetailerPaymentStatementReport() {
    const selectValuesRe = useSelector(state => state.RetailerPaymentReducer);
    const lastAdded = selectValuesRe.lastAdded;
    const snackbarValues = selectValuesRe.sucessMessage;
    const tableData = selectValuesRe.retailerPaymentReports === undefined ? [] : selectValuesRe.retailerPaymentReports;
    const dispatch = useDispatch();
    const loader = selectValuesRe?.loader;
    useEffect(() => {
        dispatch(RetailerPaymentLastAddedReset())
        return () => {
            dispatch(RetailerPaymentLastAddedReset())
        }
    }, [])
    const handleDownload = (value) => {
        const tableColumns = ["Date", "Type", "Bank", "Ref.No", "Amount", "App. Amount", "Status"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const {} in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.paymentDate)
                secondArray.push(element?.paymentType)
                secondArray.push(element?.bankAccounts.accountName)
                // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.refNo)
                secondArray.push(element?.payAmount)
                secondArray.push(element?.payApprovedAmount)
                secondArray.push(element?.paymentStatus)
                // secondArray.push(element?.updateTime)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });
        doc.text("Retailer Payment Statement Report", 50, 25);
        doc.text(currentDateWithTime, 150, 25);
        doc.addImage(KADICK_LOGO, 15, 10, 30, 20);
        if (value === 1) {
            doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
        }
        else {
            doc.save("Payment_Statement_Report.pdf")
        }
        window.open(doc.output('bloburl'), '_blank');
    }

    const handleReset = () => {
        dispatch(RetailerPaymentLastAddedReset())
    }
    const handleInputDateChange = (e) => {
        const { name, value } = e.target;
        //  alert("e.target.value"+value)
        //   console.log("RetailerCode2",e.tar)
        dispatch(RetailerPaymentLastAdded({ [name]: value }))
    }
    const columns = useMemo(
        () => [


            {
                accessorKey: 'paymentDate',
                header: 'Pay.Date',
            },
            {
                accessorKey: 'paymentType',
                header: 'Payment Type',
            },
            {
                accessorKey: 'bankAccounts.accountName',
                header: 'Bank',
            },
            {
                accessorKey: 'refNo',
                header: 'Ref.No',
            },
            {
                accessorKey: 'payAmount',
                header: 'Payment Amount',
            },
            // {
            //     accessorKey: 'payAmount',
            //     header: 'Payment Amount',
            // },
            {
                accessorKey: 'payApprovedAmount',
                header: 'Approved Amount',
            },
            {
                accessorKey: 'paymentStatus',
                header: 'Payment Status',
            },


            {
                accessorKey: 'updateTime',
                header: 'Update Time',
            },


        ],
        [],
    );
    const handleQuery = () => {
        var findDateBetween = daysDiffernece(lastAdded.startDate, lastAdded.endDate)
        if (findDateBetween < 30) {
          //  dispatch(handleLoader(true))
          dispatch(RetailerPaymentFetch({ retailerCode: lastAdded.retailerCode, startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
            //dispatch(handleLoader(false))
            dispatch(handleSnackBar({snackbarOpen:true,message:"Reports Showing for "+lastAdded.retailerCode+" date between "+lastAdded.startDate+" to "+lastAdded.endDate, mode:"success"}))
        }
        else {
            dispatch(handleSnackBar({snackbarOpen:true,message:"Date between can't exceed more than 30 days", mode:"error"}))
        }
      
    }

    const handleInputChange = (RetailerValues) => {
        console.log("RetailerCode", RetailerValues)
        //   console.log("RetailerCode2",e.tar)
        dispatch(RetailerPaymentLastAdded({ retailerCode: RetailerValues.retailer_code, retailerCodeDisplay: RetailerValues.retailer_name }))
    }

    return (
        <>


            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
              
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                renderTopToolbarCustomActions={() => (
                    <RetailerCommonWithDateComponent
                        selectValuesRe={selectValuesRe}
                        handleQuery={handleQuery}
                        retailerCodeChange={handleInputChange}
                        lastAdded={lastAdded}
                        handlePrint={() => { handleDownload(1) }}
                        handlePdf={() => { handleDownload(2) }}
                        handleInputDateChange={handleInputDateChange}
                        handleReset={handleReset}
                    />
                )}

                enableColumnOrdering

            />
               <SnackBarComponent snackbarClosed={()=>{ dispatch(handleSnackBar({snackbarOpen:false,message:"", mode:""}))}} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </>
    )
}