import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import MaterialReactTable from 'material-react-table';
import jsPDF from "jspdf";
import { currentDateWithTime } from '../../utlis/CurrentDateGenerator';
import AgentCommonWithDateComponent from "./AgentCommonWithDateComponent"
import { AllocationHistoryFetch, allocationHistoryLastAddedReset } from "../../redux/slice/AllocationHistoryReportSlice"
import { allocationHistoryLastAdded } from "../../redux/slice/AllocationHistoryReportSlice"
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { branchesFetch } from "../../redux/slice/CommonSlice";
import RadioComponent from "../../components/Radio";
import KADICK_LOGO from "../../kadick_b2c_logo.png";
export default function AllocationHistory() {
    const selectValuesRe = useSelector(state => state.AllocationHistoryReducer);
    const lastAdded = selectValuesRe.lastAdded;
    const commonValues = useSelector(state => state.CommonReducer);
   // console.log("commonValues", commonValues)
    const branches = commonValues?.branches;
    useEffect(() => {
        dispatch(branchesFetch())
        handleReset()
        return () =>{
            handleReset()
        }
    }, [])

   // console.log("lastAdded", lastAdded)
    const tableData = selectValuesRe.allocationHistoryReports;
    const dispatch = useDispatch();
    const loader = selectValuesRe?.loader;
    const handlePrint = () => {
        const tableColumns = ["Pay.Date", "Pay.Type", "Bank", "Ref.No", "Pay.Amount", "App.Amount", "Pay.Status", "Update Time"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.date)
                secondArray.push(element?.agent_code)
                secondArray.push(element?.user_name)
                // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.available_credit)
                secondArray.push(element?.current_balance)
                secondArray.push(element?.advance_amount)
                secondArray.push(element?.credit_limit)
                secondArray.push(element?.daily_limit)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });

        doc.text("Agent Wallet Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage(KADICK_LOGO, 15, 10, 30, 20);

        doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
        window.open(doc.output('bloburl'), '_blank');
    }
    const handleInputDateChange = (e) => {
        const { name, value } = e.target;
        //  alert("e.target.value"+value)
        //   console.log("agentCode2",e.tar)
        dispatch(allocationHistoryLastAdded({ [name]: value }))
    }

    const handlePdf = () => {
        const tableColumns = ["Date", "Code", "User Name", "Available Credit", "Cur.Balance", "Adv.Amount", "Cre.Limit", "Daily Limit"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.date)
                secondArray.push(element?.agent_code)
                secondArray.push(element?.user_name)
                // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.available_credit)
                secondArray.push(element?.current_balance)
                secondArray.push(element?.advance_amount)
                secondArray.push(element?.credit_limit)
                secondArray.push(element?.daily_limit)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, {
            startY: 30, startX: 0, autoSize: true, styles: {
                overflow: 'linebreak',
                fontSize: 8
            },
            showHeader: 'everyPage',
        });

        doc.text("Agent Wallet Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage(KADICK_LOGO, 15, 10, 30, 20);

        doc.save();
        window.open(doc.output('bloburl'), '_blank');
    }
    const columns = useMemo(
        () => [


            {
                accessorKey: 'branch',
                header: 'Branch',

            },
            {
                accessorKey: 'operator',
                header: 'Operator',
            },

            {
                accessorKey: 'den',
                header: 'Den.',
            },

            {
                accessorKey: 'ssno',
                header: 'Start Sl No',
            },
            {
                accessorKey: 'esno',
                header: 'End Sl No',
            },
            {
                accessorKey: 'rCount',
                header: 'Request Count',
            },


            {
                accessorKey: 'aCount',
                header: 'Allocated Count',
            },


        ],
        [],
    );
    const handleQuery = () => {
        dispatch(AllocationHistoryFetch({ branch: lastAdded.branch, type: lastAdded.type, startDate:lastAdded.startDate, endDate: lastAdded.endDate, startSerialNo:lastAdded.startSerialNo, endSerialNo:lastAdded.endSerialNo }))
    }
    const handleReset = () =>{
        dispatch(allocationHistoryLastAddedReset())
    }

    const handleChange = (e, branchValues) => {
       // console.log("agentCode", branchValues)
        //   console.log("agentCode2",e.tar)
        dispatch(allocationHistoryLastAdded({ branch: branchValues.id, branchDisplay: branchValues.name }))
    }
    const handleInputChange = (e) => {
        dispatch(allocationHistoryLastAdded({ [e.target.name]: e.target.value, }))

    }
    return (
        <>
            <Grid container style={{ justifyContent: "space-between" }}>
                <Grid item spacing={4} lg={2} md={4} sm={4} style={{ width: '30%', minWidth: "30%", display: "flex" }}>
                    <input type='radio' name="type" checked={lastAdded.type === "branch"} id="branch" onChange={handleInputChange} value="branch" className="form-control" />
                    <Autocomplete
                        id="BranchCode"
                        options={branches}

                        value={lastAdded.branchCodeDisplay}
                        onChange={(e, newValue, name) => { handleChange(e, newValue) }}
                        sx={{ width: "100%" }}
                        renderInput={(params) => <Grid item lg={11} md={11} sm={11}> <TextField disabled={!(lastAdded.type === "branch")} name='Branch' error={lastAdded.BranchError}  {...params} label="Branch" /></Grid>}
                    />
                </Grid> <Grid spacing={4} item lg="2" md={4} sm={4} style={{ display: "flex", width: '33%', minWidth: "33%", display: "flex" }}>
                    <input type='radio' name="type" id="date" checked={lastAdded.type === "date"} onChange={handleInputChange} value="date" className="form-control" />
                    <TextField disabled={!(lastAdded.type === "date")} value={lastAdded.startDate} type="date" placeholder="Date" onChange={handleInputChange} name="startDate" label="Start Date" fullWidth={true} />
                    &nbsp;
                    <TextField disabled={!(lastAdded.type === "date")} value={lastAdded.endDate} type="date" placeholder="Date" onChange={handleInputChange} name="endDate" label="End Date" fullWidth={true} />
                </Grid>

                <Grid item lg={3} spacing={4} md={2} sm={2} style={{ display: "flex", width: '33%', minWidth: "33%" }}>
                    <input type='radio' checked={lastAdded.type === "serialNo"} name="type" id="serialNo" onChange={handleInputChange} value="serialNo" className="form-control" />
                    <TextField
                        value={lastAdded.startSerialNo}
                        //  style={{ width: "72%" }}
                        fullWidth={true}
                        placeholder="Start Serial No"
                        type="text"
                        disabled={!(lastAdded.type === "serialNo")}
                        onChange={handleInputChange}
                        name="startSerialNo"

                        error={lastAdded.startSerialNoError}
                        helperText={lastAdded.startSerialNoHelperText}
                        label="Start Serial No" />
                    &nbsp;

                    <TextField
                        value={lastAdded.endSerialNo}
                        //  style={{ width: "72%" }}
                        fullWidth={true}
                        placeholder="End Serial No"
                        type="text"
                        disabled={!(lastAdded.type === "serialNo")}
                        onChange={handleInputChange}
                        name="endSerialNo"

                        error={lastAdded.endSerialNoError}
                        helperText={lastAdded.endSerialNoHelperText}
                        label="End Serial No" />  </Grid>
            </Grid>
          
            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                renderTopToolbarCustomActions={() => (
                    <Grid item lg="12" md={4} sx={{ margin: "auto" }} sm={4}>
                    <Button variant='contained'  onClick={handleQuery}>Query</Button>&nbsp;
                    <Button variant='contained' onClick={handleReset} color='primary'>Reset</Button>&nbsp;
                    {/* <DownloadButton classes={classes} handlePdf={() => { handlePdf(1) }} handlePrint={() => { handlePdf(2) }} /> */}
                </Grid>
                )}

                enableColumnOrdering

            />
        </>
    )
}