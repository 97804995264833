import { Autocomplete, Button, Grid, TextField } from "@mui/material"
import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import AgentCommonComponent from "./AgentCommonComponent"
import MaterialReactTable from 'material-react-table';
import { AgentWalletLastAddedReset, agentWalletFetch, agentWalletLastAdded, handleLoader } from "../../redux/slice/AgentWalletReportSlice"
import jsPDF from "jspdf";
import { currentDateWithTime } from '../../utlis/CurrentDateGenerator';
import KADICK_LOGO from "../../kadick_b2c_logo.png";
export default function AgentWalletReport() {
    const selectValuesRe = useSelector(state => state.AgentWalletReducer);
    const lastAdded = selectValuesRe.lastAdded;
    console.log("lastAdded", lastAdded)
    const tableData = selectValuesRe.agentWallets === undefined ? [] : selectValuesRe.agentWallets;
    const dispatch = useDispatch();
    const loader = selectValuesRe.loader;
    useEffect(() => {
        dispatch(AgentWalletLastAddedReset())
        return () => {
            dispatch(AgentWalletLastAddedReset())
        }
    }, [])
    // const handlePrint = () => {
    //     const tableColumns = ["Date", "Code", "User Name", "Avai.Credit", "Cur.Balance", "Adv.Amount", "Cre.Limit", "Daily Limit"];
    //     const doc = new jsPDF();
    //     const firstArray = [];
    //     var secondArray = [];
    //     tableData.forEach(element => {
    //         secondArray = [];
    //         //  //console.log("element[x].toString()1",element)
    //         for (const x in element) {
    //             //console.log("element[x].toString()1",element)
    //             secondArray.push(element?.date)
    //             secondArray.push(element?.agent_code)
    //             secondArray.push(element?.user_name)
    //             // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
    //             secondArray.push(element?.available_credit)
    //             secondArray.push(element?.current_balance)
    //             secondArray.push(element?.advance_amount)
    //             secondArray.push(element?.credit_limit)
    //             secondArray.push(element?.daily_limit)

    //         }
    //         firstArray.push(secondArray)
    //     });
    //     doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });

    //     doc.text("Agent Wallet Report", 70, 25);
    //     doc.text(currentDateWithTime, 140, 25);
    //     doc.addImage("../../kadick_b2c_logo.png", 15, 10, 30, 20);

    //     doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
    //     window.open(doc.output('bloburl'), '_blank');
    // }

    const handleDownload = (value) => {
        const tableColumns = ["Date", "Code", "User Name", "Available Credit", "Cur.Balance", "Adv.Amount", "Cre.Limit", "Daily Limit"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.date)
                secondArray.push(element?.agent_code)
                secondArray.push(element?.user_name)
                // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.available_credit)
                secondArray.push(element?.current_balance)
                secondArray.push(element?.advance_amount)
                secondArray.push(element?.credit_limit)
                secondArray.push(element?.daily_limit)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, {
            startY: 30, startX: 0, autoSize: true, styles: {
                overflow: 'linebreak',
                fontSize: 8
            },
            showHeader: 'everyPage',
        });

        doc.text("Agent Wallet Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage(KADICK_LOGO, 15, 10, 30, 20);

        if (value === 1) {
            doc.save("agent_wallet_report.pdf");
        }
        else {
            doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
        }
        window.open(doc.output('bloburl'), '_blank');
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'date',
                header: 'Date',
            },

            {
                accessorKey: 'agent_code',
                header: 'Code',
            },
            {
                accessorKey: 'user_name',
                header: 'User Name ',
            },
            {
                accessorKey: 'available_credit',
                header: 'Avail.Credit ',
            },
            {
                accessorKey: 'current_balance',
                header: 'Cur.Balance ',
            },
            {
                accessorKey: 'advance_amount',
                header: 'Adv.Amount ',
            },
            {
                accessorKey: 'credit_limit',
                header: 'Cre.Limit ',
            },
            {
                accessorKey: 'daily_limit',
                header: 'Daily Limit ',
            },

        ],
        [],
    );
    const handleQuery = () => {
        //  dispatch(handleLoader(true))
        dispatch(agentWalletFetch({ agentCode: lastAdded.agentCode }))
        // dispatch(handleLoader(false))
    }

    const handleInputChange = (agentValues) => {
        //console.log("agentCode",agentValues)

        dispatch(agentWalletLastAdded({ agentCode: agentValues.agent_code, agentCodeDisplay: agentValues.agent_code === "ALL" ? agentValues.label : agentValues.agent_code + " - " + agentValues.agent_name }))
    }

    const handleReset = () => {
        dispatch(AgentWalletLastAddedReset())
    }
    return (
        <>


            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                renderTopToolbarCustomActions={() => (
                    <AgentCommonComponent
                        selectValuesRe={selectValuesRe}
                        handleQuery={handleQuery}
                        agentCodeChange={handleInputChange}
                        lastAdded={lastAdded}
                        handlePrint={() => { handleDownload(2) }}
                        handlePdf={() => { handleDownload(1) }}
                        handleReset={handleReset}
                    />
                )}

                enableColumnOrdering

            />
        </>
    )
}