import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import MaterialReactTable from 'material-react-table';
import jsPDF from "jspdf";
import { currentDateWithTime, daysDiffernece } from '../../utlis/CurrentDateGenerator';
import RetailerCommonWithDateComponent from "./RetailerWithDateComponent"
import { RetailerAdjustmentFetch, handleSnackBar, retailerAdjustmentLastAddedReset } from "../../redux/slice/RetailerAdjustmentReportSlice"
import { retailerAdjustmentLastAdded } from "../../redux/slice/RetailerAdjustmentReportSlice"
import SnackBarComponent from "../../components/snackbar";
import KADICK_LOGO from "../../kadick_b2c_logo.png";
export default function RetailerAdjustmentStatementReport() {
    const selectValuesRe = useSelector(state => state.RetailerAdjustmentReducer);
    const lastAdded = selectValuesRe.lastAdded;
   // console.log("lastAdded", lastAdded)
    const tableData = selectValuesRe.retailerAdjustmentReports === undefined ? [] : selectValuesRe.retailerAdjustmentReports;
    const dispatch = useDispatch();
    const snackbarValues = selectValuesRe.sucessMessage;
    const loader = selectValuesRe?.loader;
    // useEffect(()=>{
    //     dispatch(retailerAdjustmentLastAddedReset())
    //     return () =>{
    //         dispatch(retailerAdjustmentLastAddedReset())
    //     }
    // })
    const handlePrint = (value) => {
        const tableColumns = ["Date", "Type", "Ref.No", "Amount", "Approved Amount", "Status", "Update Time"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.adjustmentDate)
                secondArray.push(element?.adjustmentType)
                secondArray.push(element?.refNo)
                // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.adjAmount)
                secondArray.push(element?.adjApprovedAmount)
                secondArray.push(element?.adjustmentStatus)
                secondArray.push(element?.updateTime)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });

        doc.text("Retailer Wallet Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage(KADICK_LOGO, 15, 10, 30, 20);
        if (value === 1) {
            doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
        }
        else {
            doc.save("Retailer_Adjustment_Report.pdf")
        }
        window.open(doc.output('bloburl'), '_blank');
    }
    const handleInputDateChange = (e) => {
        const { name, value } = e.target;
        //  alert("e.target.value"+value)
        //   console.log("retailerCode2",e.tar)
        dispatch(retailerAdjustmentLastAdded({ [name]: value }))
    }


    const columns = useMemo(
        () => [


            {
                accessorKey: 'adjustmentDate',
                header: 'Date',
            },
            {
                accessorKey: 'adjustmentType',
                header: 'Type',
            },

            {
                accessorKey: 'refNo',
                header: 'Ref.No',
            },

            {
                accessorKey: 'adjAmount',
                header: 'Amount',
            },
            {
                accessorKey: 'adjApprovedAmount',
                header: 'Approved Amount',
            },
            {
                accessorKey: 'adjustmentStatus',
                header: 'Status',
            },


            {
                accessorKey: 'updateTime',
                header: 'Update Time',
            },


        ],
        [],
    );
    const handleQuery = () => {
        var findDateBetween = daysDiffernece(lastAdded.startDate, lastAdded.endDate)
        if (findDateBetween < 30) {
            dispatch(RetailerAdjustmentFetch({ retailerCode: lastAdded.retailerCode, startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
            dispatch(handleSnackBar({ snackbarOpen: true, message: "Reports Showing for " + lastAdded.retailerCode + " date between " + lastAdded.startDate + " to " + lastAdded.endDate, mode: "success" }))
        }
        else {
            dispatch(handleSnackBar({ snackbarOpen: true, message: "Date between can't exceed more than 30 days", mode: "error" }))
        }
    }

    const handleReset = () => {
        dispatch(retailerAdjustmentLastAddedReset())
    }

    const handleInputChange = (retailerValues, e) => {
       // console.log("retailerCode", retailerValues)
        //   console.log("retailerCode2",e.tar)
        dispatch(retailerAdjustmentLastAdded({ retailerCode: retailerValues.retailer_code, retailerCodeDisplay: retailerValues.retailer_name }))
    }

    return (
        <>


            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}

                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                renderTopToolbarCustomActions={() => (
                    <RetailerCommonWithDateComponent
                        selectValuesRe={selectValuesRe}
                        handleQuery={handleQuery}
                        retailerCodeChange={handleInputChange}
                        lastAdded={lastAdded}
                        handlePrint={() => { handlePrint(1) }}
                        handlePdf={() => { handlePrint(2) }}
                        handleInputDateChange={handleInputDateChange}
                        handleReset={handleReset}
                    // retailerCodeDisplay = {lastAdded.retailerCodeDisplay}
                    />
                )}

                enableColumnOrdering

            />
            <SnackBarComponent snackbarClosed={() => { dispatch(handleSnackBar({ snackbarOpen: false, message: "", mode: "" })) }} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />

        </>
    )
}