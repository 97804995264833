import React, { useMemo,useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import {  Button, debounce} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { allStockFetch, handleVoucherAllStockResetDispatch } from '../../redux/slice/VoucherStockSlice';
import jsPDF from 'jspdf';
import { currentDateWithTime } from '../../utlis/CurrentDateGenerator';
import CircularStatic from '../../components/spinner';
import KADICK_LOGO from "../../kadick_b2c_logo.png"
require('jspdf-autotable');

const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            justifyContent: "end",
            flexDirection: "row"
        }
    }
});
const AllStock = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.VoucherStockReducer);
    const tableData = selectValuesRe?.allStocks === undefined ? [] : selectValuesRe?.allStocks;
    const loader = selectValuesRe?.loader;
    useEffect(()=>{
        dispatch(handleVoucherAllStockResetDispatch())
    },[])

    const handlePrint = () => {
        const tableColumns = ["Date", "Operator", "Denomination", "Status", "Count"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
          //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.coperators?.date)
                secondArray.push(element?.coperators?.operatorCode)
                secondArray.push(element?.vocuherValue)
                const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(status)
                secondArray.push(element?.count)
                
            }
            firstArray.push(secondArray)
        });       
        doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });

        doc.text("Stock Report", 85, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage(KADICK_LOGO, 15, 10,30,20);
        
        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
    }

    const handleQuery = debounce (function() {
        dispatch(allStockFetch())
    },1500)

    const handleReset = () => {
        dispatch(handleVoucherAllStockResetDispatch())
    }
   

    const columns = useMemo(
        () => [
            {
                accessorKey: 'coperators.date',
                header: 'Date',
                
            },
            {
                accessorKey: 'coperators.operatorCode',
                header: 'Operator',
            },
            {
                accessorKey: 'vocuherValue',
                header: 'Denomination',
            },
            {
                accessorKey: 'status',
                header: 'Status',
                enableSorting:true,
                Cell: ({ cell }) => (
                    cell.getValue()?.toString()?.toString() === "1" ? "Available" : cell.getValue()?.toString() === "2" ? "Used" : cell.getValue()?.toString() === "3" ? "Expired" : cell.getValue()?.toString() === "4" ? 'Blocked' : 'Suspend'
                )
            },
            {
                accessorKey: 'count',
                header: 'Count',
            },
        ],
        [],
    );
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        < div className={classes.root}>
             {loader===true ? <CircularStatic /> : null }
          
            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                renderTopToolbarCustomActions={() => (
                    <>
                      <Button variant='contained' color='primary' onClick={handleQuery}>Get All Stock</Button>&nbsp;
                        <Button variant='contained' color='primary' disabled={tableData.length <= 0} onClick={handlePrint}>Print</Button>&nbsp;
                        <Button variant='contained' color='primary' disabled={tableData.length <= 0} onClick={handleReset}>Reset</Button>&nbsp;
                    </>
                  )}
                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                initialState={{
                    density: "compact"
                }}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                positionActionsColumn="last"
                enableColumnOrdering
            />
          
            <div>

            </div>
        </div>
    );
};
export default AllStock;
