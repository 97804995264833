import React, { useMemo, useEffect } from 'react';
import MaterialReactTable from 'material-react-table';
import { makeStyles } from '@material-ui/styles';
import { Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import jsPDF from 'jspdf';
import { currentDateWithTime } from '../../utlis/CurrentDateGenerator';
import CircularStatic from '../../components/spinner';
import { cardVoucherAllStocksFetch, handleCardVoucherAllStockResetDispatch } from '../../redux/slice/CardVoucherAllStockSlice';
import KADICK_LOGO from "../../kadick_b2c_logo.png"
require('jspdf-autotable');

const useStyles = makeStyles({
    root: {
        "& .css-sq9qdz": {
            justifyContent: "end",
            flexDirection: "row"
        }
    }
});
const CardVoucherAllStock = () => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const selectValuesRe = useSelector(state => state.CardVoucherAllStocksReducer);
    const tableData = selectValuesRe?.cardVoucherAllStocks ?  selectValuesRe?.cardVoucherAllStocks : [];
    const loader = selectValuesRe?.loader;
    useEffect(() => {
        dispatch(handleCardVoucherAllStockResetDispatch())
    }, [])

    const handlePrint = () => {
        const tableColumns = ["Date", "Operator", "Denomination", "Status", "Count"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.coperators?.date)
                secondArray.push(element?.coperators?.operatorCode)
                secondArray.push(element?.vocuherValue)
                const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(status)
                secondArray.push(element?.count)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, { startY: 30, startX: 0, autoSize: true, });

        doc.text("Card All Stock Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage(KADICK_LOGO, 15, 10, 30, 20);

        doc.autoPrint();
        window.open(doc.output('bloburl'), '_blank');
    }

    const handleQuery = () => {
        dispatch(cardVoucherAllStocksFetch())
    }

    const handleReset = () => {
        dispatch(handleCardVoucherAllStockResetDispatch())
    }


    const columns = useMemo(
        () => [
            {
                accessorKey: 'coperators.date',
                header: 'Date',

            },
            {
                accessorKey: 'coperators.operatorCode',
                header: 'Operator',
            },
            {
                accessorKey: 'vocuherValue',
                header: 'Denomination',
            },
            {
                accessorKey: 'status',
                header: 'Status',
                enableSorting: true,
                Cell: ({ cell }) => (
                  //  if (b.status=1,'Available', if(b.status=2,'Used',if(b.status=3,'Expired',if(b.status=4,'Blocked',if(b.status=5,'Suspend', if(b.status=6,'Reserved',if(b.status=7,'Staged','Other')))))))
                    cell.getValue()?.toString()=== "1" ? "Available" : cell.getValue()?.toString() === "2" ? "Used" : cell.getValue()?.toString() === "3" ? "Expired" : cell.getValue()?.toString() === "4" ? 'Blocked' : cell.getValue()?.toString() === "5" ? "Suspend" :  cell.getValue()?.toString() === "6" ? "Reserved" :  cell.getValue()?.toString() === "7" ? "Staged" : "Other"
                )
            },
            {
                accessorKey: 'count',
                header: 'Count',
            },
        ],
        [],
    );
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };



    return (
        < div className={classes.root}>
            {loader === true ? <CircularStatic /> : null}

            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                renderTopToolbarCustomActions={() => (
                    <>
                        <Button variant='contained' color='primary' onClick={handleQuery}>Get All Stock</Button>&nbsp;
                        <Button variant='contained' color='primary' disabled={tableData.length <= 0} onClick={handlePrint}>Print</Button>&nbsp;
                        <Button variant='contained' color='primary' disabled={tableData.length <= 0} onClick={handleReset}>Reset</Button>&nbsp;
                    </>
                )}
                displayColumnDefOptions={{

                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                positionActionsColumn="last"
                enableColumnOrdering
            />

            <div>

            </div>
        </div>
    );
};
export default CardVoucherAllStock;
