import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import MaterialReactTable from 'material-react-table'
import jsPDF from "jspdf"
import { currentDateWithTime, daysDiffernece } from '../../utlis/CurrentDateGenerator'
import RetailerWithDateComponent from "./RetailerWithDateComponent"
import { RetailerFlexiFetch, retailerFlexiLastAdded, retailerFlexiLastAddedReset, handleLoader, handleSnackBar } from "../../redux/slice/RetailerFlexiReportSlice"
import SnackBarComponent from "../../components/snackbar"
import KADICK_LOGO from "../../kadick_b2c_logo.png";
export default function RetailerFlexiReport() {
    const selectValuesRe = useSelector(state => state.RetailerFlexiReducer);
    const lastAdded = selectValuesRe.lastAdded;
    //console.log("lastAdded", lastAdded)
    const tableData = selectValuesRe.retailerFlexiReports === undefined ? [] : selectValuesRe.retailerFlexiReports;
    const dispatch = useDispatch();
    const loader = selectValuesRe?.loader;
    const snackbarValues = selectValuesRe.sucessMessage;
    useEffect(() => {
        dispatch(retailerFlexiLastAddedReset())
        return () => {
            dispatch(retailerFlexiLastAddedReset())
        }
    }, [])


    const handleDownload = (value) => {
        const tableColumns = ["#", "Retailer Code", "Operator Code", "Dest MSISDN", "Amount", "Create Time"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  //console.log("element[x].toString()1",element)
            for (const x in element) {
                //console.log("element[x].toString()1",element)
                secondArray.push(element?.id)
                secondArray.push(element?.retailer_code)
                secondArray.push(element?.operator_code)
                // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.dest_msisdn)
                secondArray.push(element?.amount)
                secondArray.push(element?.create_time)


            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, {
            startY: 30, startX: 0, autoSize: true, 
            // styles: {
            //     overflow: 'linebreak',
            //     fontSize: 8
            // },
          //  showHeader: 'everyPage',
        });

        doc.text("Retailer Flexi Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage(KADICK_LOGO, 15, 10, 30, 20);
        if (value === 1) {
            doc.save("retailer_flexi_report.pdf");
        }
        else {
            doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
        }
        window.open(doc.output('bloburl'), '_blank');
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: '#',
            },

            {
                accessorKey: 'retailer_code',
                header: 'Retailer Code',
            },
            {
                accessorKey: 'operator_code',
                header: 'Operator Code',
            },
            {
                accessorKey: 'dest_msisdn',
                header: 'Dest MSISDN',
            },
            {
                accessorKey: 'amount',
                header: 'Amount',
            },
            {
                accessorKey: 'create_time',
                header: 'Create Time',
                render:()=>{
                    <p>Hi</p>
                }
            },


        ],
        [],
    );




    const handleQuery = () => {
        var findDateBetween = daysDiffernece(lastAdded.startDate, lastAdded.endDate)
        if (findDateBetween < 7) {
          //  dispatch(handleLoader(true))
            dispatch(RetailerFlexiFetch({ retailerCode: lastAdded.retailerCode, startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
            //dispatch(handleLoader(false))
            dispatch(handleSnackBar({snackbarOpen:true,message:"Reports Showing for "+lastAdded.retailerCode+" date between "+lastAdded.startDate+" to "+lastAdded.endDate, mode:"success"}))
        }
        else {
            dispatch(handleSnackBar({snackbarOpen:true,message:"Date between can't exceed more than 7 days", mode:"error"}))
        }
    }

    const handleInputChange = (retailerValues, e) => {
      //  console.log("retailerCode", retailerValues)
        //   console.log("retailerCode2",e.tar)
        dispatch(retailerFlexiLastAdded({ retailerCode: retailerValues.retailer_code, retailerCodeDisplay: retailerValues.retailer_code === "ALL" ? retailerValues.label : retailerValues.retailer_code + " - " + retailerValues.retailer_name }))
    }
    const handleInputDateChange = (e) => {
        const { name, value } = e.target;
        dispatch(retailerFlexiLastAdded({ [name]: value }))
    }
    const handleReset = () => {
        dispatch(retailerFlexiLastAddedReset())
    }
    return (
        <>


            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader
                }}
                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                renderTopToolbarCustomActions={() => (
                    <RetailerWithDateComponent
                        selectValuesRe={selectValuesRe}
                        handleQuery={handleQuery}
                        retailerCodeChange={handleInputChange}
                        lastAdded={lastAdded}
                        handlePrint={() => { handleDownload(2) }}
                        handlePdf={() => { handleDownload(1) }}
                        handleInputDateChange={handleInputDateChange}
                        handleReset={handleReset}
                    />
                )}

                enableColumnOrdering

            />
            <SnackBarComponent snackbarClosed={()=>{ dispatch(handleSnackBar({snackbarOpen:false,message:"", mode:""}))}} dispatch={dispatch} mode={snackbarValues.mode} open={snackbarValues.snackbarOpen} message={snackbarValues.message} />


        </>

    )
}