import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { useDispatch } from "react-redux"
import OperatorCommonComponent from "./OperatorCommonComponent"
import MaterialReactTable from 'material-react-table';
import jsPDF from "jspdf";
import { currentDateWithTime } from '../../utlis/CurrentDateGenerator';
import KADICK_LOGO from "../../kadick_b2c_logo.png";
import { operatorWalletBalanceLastAddedReset, OperatorWalletBalanceFetch, operatorWalletBalanceLastAdded } from "../../redux/slice/OperatorWalletBalanceReportSlice"
export default function OperatorWalletBalanceReport() {
    const selectValuesRe = useSelector(state => state.OperatorWalletBalanceReducer);
    const intialvsibilitObj = { atlWallet: true, atlWalletTime: true, gloWallet: true, gloWalletTime: true, "9mWallet": true, "9mWalletTime": true, mtn2Wallet: true, mtn2WalletTime: true, mtn3Wallet: true, mtn3WalletTime: true, runDate: true, mtnWalletBalance: true, mtnWalletBalanceTime: true, }
    const lastAdded = selectValuesRe.lastAdded;
    //console.log("lastAdded", lastAdded)
    const tableData = selectValuesRe.operatorWalletBalanceReports === undefined ? [] : selectValuesRe.operatorWalletBalanceReports;
    const [columnObj, setColumnObj] = React.useState(intialvsibilitObj)
    const dispatch = useDispatch();
    const loader = selectValuesRe.loader;
    useEffect(() => {
        dispatch(operatorWalletBalanceLastAddedReset())
        return () => {
            dispatch(operatorWalletBalanceLastAddedReset())
        }
    }, [])
    //console.log(columnObj)

    const handleDownload = (value) => {
        const tableColumns = ["Run Date", "MTN Wallet", "MTN Wallet Time", "MTN2 Wallet (2348136371854)",
            "MTN2 Wallet Time (2348136371854)", "MTN3 Wallet", "MTN3 Wallet Time (2347034890606)", "ATL Wallet"
            , "ATL Wallet Time", "GLO Wallet", "GLO Wallet Time", "9M Wallet", "9M Wallet Time"];
        const doc = new jsPDF();
        const firstArray = [];
        var secondArray = [];
        tableData.forEach(element => {
            secondArray = [];
            //  ////console.log("element[x].toString()1",element)
            for (const x in element) {
                ////console.log("element[x].toString()1",element)
                secondArray.push(element?.runDate)
                secondArray.push(element?.mtnWalletBalance)
                secondArray.push(element?.mtnWalletBalanceTime)
                // const status = element?.status?.toString()?.toString() === "1" ? "Available" : element?.status?.toString() === "2" ? "Used" : element?.status?.toString() === "3" ? "Expired" : element?.status?.toString() === "4" ? 'Blocked' : 'Suspend'
                secondArray.push(element?.mtn2Wallet)
                secondArray.push(element?.mtn2WalletTime)
                secondArray.push(element?.mtn3Wallet)
                secondArray.push(element?.mtn3WalletTime)
                secondArray.push(element?.atlWallet)
                secondArray.push(element?.atlWalletTime)
                secondArray.push(element["9MWallet"])
                secondArray.push(element["9MWalletTime"])
                secondArray.push(element?.gloWallet)
                secondArray.push(element?.gloWalletTime)

            }
            firstArray.push(secondArray)
        });
        doc.autoTable(tableColumns, firstArray, {
            startY: 30, startX: 0, autoSize: true, styles: {
                overflow: 'linebreak',
                fontSize: 8
            },
            showHeader: 'everyPage',
        });

        doc.text("Operator Wallet Report", 70, 25);
        doc.text(currentDateWithTime, 140, 25);
        doc.addImage(KADICK_LOGO, 15, 10, 30, 20);

        if (value === 1) {
            doc.save("operator_wallet_report.pdf");
        }
        else {
            doc.autoPrint({ styles: { overflow: 'linebreak', fontSize: 8 } });
        }
        window.open(doc.output('bloburl'), '_blank');
    }
    const columns = useMemo(
        () => [
            {
                accessorKey: 'runDate',
                header: 'Run Date',
            },

            {
                accessorKey: 'mtnWalletBalance',
                header: 'MTN Wallet',
            },
            {
                accessorKey: 'mtnWalletBalanceTime',
                header: 'MTN Wallet Time',
            },
            {
                accessorKey: 'mtn2Wallet',
                header: 'MTN2 Wallet (2348136371854)',
            },
            {
                accessorKey: 'mtn2WalletTime',
                header: 'MTN2 Wallet Time (2348136371854)',
            },
            {
                accessorKey: 'mtn3Wallet',
                header: 'MTN3 Wallet ',
            },
            {
                accessorKey: 'mtn3WalletTime',
                header: 'MTN3 Wallet Time (2347034890606)',
            },
            {
                accessorKey: 'atlWallet',
                header: 'ATL Wallet',
            },
            {
                accessorKey: 'atlWalletTime',
                header: 'ATL Wallet Time',
            },
            {
                accessorKey: 'gloWallet',
                header: 'GLO Wallet',
            },
            {
                accessorKey: 'gloWalletTime',
                header: 'GLO Wallet Time',
            },
            {
                accessorKey: '9mWallet',
                header: '9M Wallet',
            },
            {
                accessorKey: '9mWalletTime',
                header: '9M Wallet Time',
            },
        ],
        [],
    );
    const handleQuery = () => {

        dispatch(OperatorWalletBalanceFetch({ operatorCode: lastAdded.operatorCode, startDate: lastAdded.startDate, endDate: lastAdded.endDate }))
    }

    const handleInputChange = (operatorValues) => {
        //  alert(operatorValues.code)
        if (operatorValues.code === "ALL") {
            setColumnObj(intialvsibilitObj)
        }
        if (operatorValues.code === "9MC") {
            setColumnObj({
                atlWallet: false,
                atlWalletTime: false,
                gloWallet: false,
                gloWalletTime: false,
                "9mWallet": true,
                "9mWalletTime": true,
                mtn2Wallet: false,
                mtn2WalletTime: false,
                mtn3Wallet: false,
                mtn3WalletTime: false,
                mtnWalletBalance: false,
                mtnWalletBalanceTime: false,
            })
        }
        if (operatorValues.code === "ATC") {
            setColumnObj({
                atlWallet: true,
                atlWalletTime: true,
                gloWallet: false,
                gloWalletTime: false,
                "9mWallet": false,
                "9mWalletTime": false,
                mtn2Wallet: false,
                mtn2WalletTime: false,
                mtn3Wallet: false,
                mtn3WalletTime: false,
                mtnWalletBalance: false,
                mtnWalletBalanceTime: false,
            })
        }
        if (operatorValues.code === "GLC") {
            setColumnObj({
                atlWallet: false,
                atlWalletTime: false,
                gloWallet: true,
                gloWalletTime: true,
                "9mWallet": false,
                "9mWalletTime": false,
                mtn2Wallet: false,
                mtn2WalletTime: false,
                mtn3Wallet: false,
                mtn3WalletTime: false,
                mtnWalletBalance: false,
                mtnWalletBalanceTime: false,
            })
        }
        if (operatorValues.code === "MTC") {
            setColumnObj({
                atlWallet: false,
                atlWalletTime: false,
                gloWallet: false,
                gloWalletTime: false,
                "9mWallet": false,
                "9mWalletTime": false,
                mtn2Wallet: true,
                mtn2WalletTime: true,
                mtn3Wallet: true,
                mtn3WalletTime: true,
                mtnWalletBalance: true,
                mtnWalletBalanceTime: true,
            })
        }
        dispatch(operatorWalletBalanceLastAdded({ operatorCode: operatorValues.code, operatorCodeDisplay: operatorValues.name, label: operatorValues.name }))
    }
    const handleInputChange2 = (e) => {
        const { name, value } = e.target;
        dispatch(operatorWalletBalanceLastAdded({ [name]: value }))
    }
    const handleReset = () => {
        dispatch(operatorWalletBalanceLastAddedReset())
        setColumnObj(intialvsibilitObj)
    }
    return (
        <>

            <MaterialReactTable
                muiTablePaginationProps={{
                    //rowsPerPage : 5
                }}
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        muiTableHeadCellProps: {
                            align: 'center',
                        },
                        size: 120,
                    },
                }}
                state={{
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader,
                    columnVisibility: columnObj
                }}
                initialState={{
                    density: "compact",
                    isLoading: loader,
                    showProgressBars: loader,
                    showSkeletons: loader,

                }}
                enableStickyHeader={true}
                columns={columns}
                data={tableData}

                muiTableBodyRowProps={{
                    sx: {
                        padding: "0px"
                    }
                }}
                muiTableContainerProps={{
                    sx: {
                        maxHeight: "400px",
                        minHeight: "400PX"
                    }
                }}
                renderTopToolbarCustomActions={() => (
                    <OperatorCommonComponent
                        selectValuesRe={selectValuesRe}
                        handleQuery={handleQuery}
                        operatorCodeChange={handleInputChange}
                        handleInputChange2={handleInputChange2}
                        lastAdded={lastAdded}
                        setColumnObj={setColumnObj}
                        handlePrint={() => { handleDownload(2) }}
                        handlePdf={() => { handleDownload(1) }}
                        handleReset={handleReset}
                    />
                )}

                enableColumnOrdering

            />

        </>
    )
}